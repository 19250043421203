import React from "react"
import { graphql } from "gatsby"

import DefaultLayout from "../components/default-layout"
import Meta from "../components/meta"
import BlogList from "../components/blog-list"

const IndexPage = ({ data }) => {
  const defaultContents = data.allContentfulBlogPost.edges.map(content => content.node )
  const { title, author, siteUrl } = data.site.siteMetadata
  return (
    <DefaultLayout
      siteTitle={title}
      author={author}
    >
      <Meta
        meta={[
          { name: "og:url", content: siteUrl },
          { name: "og:image", content: `${siteUrl}/icons/icon-512x512.png` }
        ]}
        link={[
          { rel: "canonical", href: siteUrl }
        ]}
      />
      <BlogList defaultContents={defaultContents} />
    </DefaultLayout>
  )
}

export default IndexPage;
export const indexQuery = graphql`
  query indexQuery {
    allContentfulBlogPost(
      filter: { node_locale: { eq: "ja-JP" }, published: { eq: true } },
      sort: { order: DESC, fields: publishedAt }
    ) {
      edges {
        node {
          id
          slug
          title
          description
          thumbnail {
            gatsbyImageData(
              width: 965
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              aspectRatio: 1.6
            )
          }
          category {
            contentfulid
          }
          publishedAt
        }
      }
    }
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
  }
`;

import styled, { css } from "styled-components"
import SearchBox from "./search-box"

const open = css`
  border: 1px solid #ccc;
  border-radius: .3rem;
  padding: .3rem .5rem .3rem 1.8rem;
  margin-left: -1.8rem;
  width: 100%;
`

const closed = css`
  border: none;
  margin: 0;
  padding: 0;
  width: 0%;
`

export default styled(SearchBox)`
  display: flex;
  flex-direction: row-reverse;

  .SearchBoxInner {
    display: inline-block;
  }

  .SearchBox {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 0;
  }

  .SearchInput {
    font-size: 1rem;
    height: 3rem;
    transition: 300ms ease-in-out;
    color: ${({ theme }) => theme.foreground}
    ::placeholder {
      color: ${({ theme }) => theme.faded}
    }
    ${({ hasFocus, query }) => (hasFocus || query.length ? open : closed )}
    @media screen and (max-width: 767px) {
      transition: 200ms ease-in-out;
    }
  }
  .SearchInput:focus {
    outline: none;
    border-color: rgba(0, 139, 139, 1);
  }

  .SearchIcon {
    width: 1em;
    margin: 0.3em;
    color: ${({ theme }) => theme.foreground};
    cursor: ${({ hasFocus }) => (hasFocus ? `auto` : `pointer` )};
  }
`
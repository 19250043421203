import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parseISO from "date-fns/parseISO"
import format from "date-fns/format"
import ja from 'date-fns/locale/ja'

import {
  card,
  inner,
  thumbnailContainer,
  thumbnail as thumbnailStyle,
  contentsContainer,
  contentsTitle,
  contentsBody,
  contentsFooter
} from "./index.css"

const Card = ({ title, description, slug, thumbnail, publishedAt }) => {
  const publishedDate = format(parseISO(publishedAt), "yyyy年MM月dd日", { locale: ja })
  return (
    <>
      <div className={card}>
        <Link
          className={inner}
          to={slug}
        >
          <div className={thumbnailContainer}>
            <GatsbyImage
              className={thumbnailStyle}
              image={thumbnail.gatsbyImageData}
              loading="eager"
              fadin="false"
              alt=""
            />
          </div>
          <div className={contentsContainer}>
            <h2 className={contentsTitle}>{title}</h2>
            <p className={contentsBody}>{description}</p>
            <div className={contentsFooter}>
              <time>{publishedDate}</time>
            </div>
          </div>
        </Link>
      </div>
    </>
  )
};

export default Card;

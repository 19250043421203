import React from "react"
import {
  Hits,
  Index,
  PoweredBy,
} from "react-instantsearch-dom"
import Card from "../card"

const PageHit = ({ hit }) => {
  const { objectID, title, description, slug, thumbnail, publishedAt } = hit
  return (
    <div>
      <Card
        key={objectID}
        title={title}
        description={description}
        slug={slug}
        thumbnail={thumbnail}
        publishedAt={publishedAt}
      />
    </div>
  )
}

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <Hits hitComponent={PageHit} />
  </Index>
)

const SearchResult = ({ indicies, className }) => (
  <div className={className}>
    {indicies.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
    <PoweredBy />
  </div>
)

export default SearchResult
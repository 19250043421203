import styled from "styled-components"
import SearchResult from "./search-result"

export default styled(SearchResult)`
  .ais-Hits {
    .ais-Hits-list {
      list-style: none;
      padding-left: 0;
      margin: 0;
    }
  }
  .ais-PoweredBy {
    display: flex;
    justify-content: flex-end;
    font-size: 80%;

    svg {
      width: 70px;
    }
  }
`
import { default as React, useState } from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import useFocus from "./use-focus"

export default connectSearchBox(
  ({ refine, className, boxRef, onFocus, onMouseLeave }) => {
    const [onComposition, setOnComposition ] = useState(false)
    const [inputRef, setFocus] = useFocus()
    const onButtonClick = () => {
      onFocus()
      setFocus()
    }

    return (
      <div className={className}>
        <div
          className="SearchBoxInner"
          onMouseLeave={onMouseLeave}
          ref={boxRef}
        >
          <form
            className="SearchBox"
            onSubmit={e => e.preventDefault()}
          >
            <input
              className="SearchInput"
              type="text"
              placeholder="Search"
              aria-label="Search"
              ref={inputRef}
              onCompositionStart={_ => setOnComposition(true)}
              onCompositionEnd={_ => setOnComposition(false)}
              onChange={e => onComposition ? null : refine(e.target.value)}
              onFocus={onFocus}
            />
            <FontAwesomeIcon
              className="SearchIcon"
              icon="search"
              onClick={onButtonClick}
            />
          </form>
        </div>
      </div>
    )
  }
)
import React from "react"
import Card from "../card"
import Search from "../search"

const searchIndicies = [{ name: `Articles`, title: `Articles` }]

const BlogList = ({ defaultContents }) => {
  const cards = defaultContents.map(content => {
    return <Card key={content.id} {...content} />
  })
  return (
    <>
      <Search
        indicies={searchIndicies}
        defaults={cards}
      />
    </>
  )
}

export default BlogList
import algoriasearch from "algoliasearch/lite"
import { createRef, default as React, useState, useMemo } from "react"
import { InstantSearch } from "react-instantsearch-dom"
import { ThemeProvider } from "emotion-theming"
import StyledSearchBox from "./styled-search-box"
import StyledSearchResult from "./styled-search-result"
import StyledSearchRoot from "./styled-search-root"
import useClickOutside from "./use-click-outside"

const theme = {
  foreground: "#050505",
  background: "white",
  faded: "#888",
}

export default function Search({ indicies, defaults }) {
  const boxRef = createRef()
  const [query, setQuery] = useState("")
  const [hasFocus, setFocus] = useState(false)
  const searchClient = useMemo(
    () =>
      algoriasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )

  useClickOutside(boxRef, () => setFocus(false))

  const result = query && query.length > 0 ?
    <StyledSearchResult show={true} indicies={indicies} /> : defaults

  return (
    <ThemeProvider theme={theme}>
      <StyledSearchRoot>
        <InstantSearch
          searchClient={searchClient}
          indexName={indicies[0].name}
          onSearchStateChange={({ query }) => setQuery(query)}
        >
              <StyledSearchBox
                onFocus={() => setFocus(true)}
                hasFocus={hasFocus}
                query={query}
                onMouseLeave={() => setFocus(false)}
                boxRef={boxRef}
              />
          {result}
        </InstantSearch>
      </StyledSearchRoot>
    </ThemeProvider>
  )
}